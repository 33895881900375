// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-as-built-plans-preparations-js": () => import("./../../../src/pages/services/as-built-plans-preparations.js" /* webpackChunkName: "component---src-pages-services-as-built-plans-preparations-js" */),
  "component---src-pages-services-build-js": () => import("./../../../src/pages/services/build.js" /* webpackChunkName: "component---src-pages-services-build-js" */),
  "component---src-pages-services-consultancy-js": () => import("./../../../src/pages/services/consultancy.js" /* webpackChunkName: "component---src-pages-services-consultancy-js" */),
  "component---src-pages-services-electrical-system-maintenance-and-testing-js": () => import("./../../../src/pages/services/electrical-system-maintenance-and-testing.js" /* webpackChunkName: "component---src-pages-services-electrical-system-maintenance-and-testing-js" */),
  "component---src-pages-services-engineering-designs-js": () => import("./../../../src/pages/services/engineering-designs.js" /* webpackChunkName: "component---src-pages-services-engineering-designs-js" */),
  "component---src-pages-services-project-management-js": () => import("./../../../src/pages/services/project-management.js" /* webpackChunkName: "component---src-pages-services-project-management-js" */),
  "component---src-pages-services-trading-js": () => import("./../../../src/pages/services/trading.js" /* webpackChunkName: "component---src-pages-services-trading-js" */)
}

